<template>
  <div class="container">
    <div class="logo">
      <img src="@/assets/img/patientend/logo@2x.png" alt="" />
    </div>
    <patient-list></patient-list>
  </div>
</template>

<script>
// import {Form, FormItem} from 'view-design'
import patientList from "./patientend/items/patientList";
export default {
  data() {
    return {
      patientList: ["赵婷婷", "张乐乐", "李思思", "崔苗苗"],
      current: 0,
    };
  },

  components: {
    patientList,
  },

  computed: {},

  methods: {
    choosePatient(index) {
      // console.log(index)
      this.current = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: url("~@/assets/img/patientend/loginbackground.png") no-repeat;
  background-position: center;
  background-size: cover;
  .logo {
    margin-top: 130px;
    margin-bottom: 84px;
    img {
      height: 101px;
      width: 721px;
    }
  }
  .login-box {
    width: 850px;
    height: 450px;
    border-radius: 30px;
    background-color: #fff;
    border: 1px solid #d2d2d2;
    box-shadow: 1px 6px 38px 1px rgba(35, 97, 152, 0.15);
    .hd {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100px;
      border-radius: 30px 30px 0 0;
      background: rgba(65, 116, 247, 0.1);
      border-bottom: 1px solid #d2d2d2;
      span {
        font-size: 30px;
        color: #000;
      }
      span.count {
        margin: 0 8px;
        color: rgba(25, 130, 231, 1);
      }
      .close {
        position: absolute;
        right: 40px;
        top: 40px;
        width: 24px;
        height: 24px;
        background: url("~@/assets/img/patientend/xclose.png") no-repeat;
        background-position: center;
        background-size: cover;
        cursor: pointer;
      }
    }
    .bt {
      padding-bottom: 60px;
      border-radius: 0 0 30px 30px;
      background-color: #fff;
      .tips {
        margin-top: 41px;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: rgba(255, 39, 0, 1);
      }
      ul.names {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 44px;
        padding: 0 75px;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 160px;
          height: 54px;
          background: #f5f5f5;
          border: 1px solid #d8d8d9;
          border-radius: 5px;
          cursor: pointer;
          font-size: 24px;
          color: #000;
        }
        .active {
          color: #1982e7;
          background: #e7f2fd;
          border: 1px solid #4174f7;
        }
      }
      .loginbtn {
        width: 550px;
        height: 70px;
        margin-left: 150px;
        margin-top: 56px;
        border: 0;
        background: #ededed;
        border-radius: 5px;
        font-size: 30px;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
