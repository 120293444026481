<template>
  <div class="selectPatientBox">
    <Modal
      v-model="isListShow"
      class-name="vertical-center-modal modalHead selectpatient"
      :footer-hide="true"
      @on-cancel="clickClose"
    >
      <template slot="header">
        <div class="title">
          <span>当前手机号码查询到</span>
          <span class="num">{{ dataList.length }}</span>
          <span>位训练者，请选择登录</span>
        </div>
      </template>
      <template slot="close">
        <div class="close"></div>
      </template>
      <Row :gutter="16">
        <i-col span="24" class="table-container">
          <Table
            :columns="tabColumns"
            :data="dataList"
            stripe
            border
            ref="selection"
            disabled-hover
            :loading="tableLoading"
            highlight-row
            :row-class-name="rowClassName"
            @on-row-click="clickPatient"
          ></Table>
        </i-col>
      </Row>
      <div class="loginbtn-box">
        <button
          @click="clickLogin"
          :class="selectedPatient === '' ? 'loginBtn' : 'loginBtnSelected'"
        >
          登录
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Debounce } from "@js/debounce.js";
export default {
  name: "patientList",
  data() {
    return {
      // selectPatient: true,
      // 加载状态
      tableLoading: false,
      isListShow: false,
      selectedPatient: "",
      // 表格数据
      tabColumns: [
        {
          title: "医院",
          key: "organizationName",
          align: "center",
          minWidth: 100,
        },
        {
          title: "姓名",
          key: "patientName",
          align: "center",
          minWidth: 30,
        },
        {
          title: "手机号码",
          key: "mobilephonenum",
          align: "center",
          minWidth: 100,
        },
        {
          title: "性别",
          key: "patientSexStr",
          align: "center",
          minWidth: 30,
        },
        {
          title: "年龄",
          key: "patientAge",
          align: "center",
          minWidth: 30,
          render: (h, params) => {
            const row = params.row;
            if (
              row.patientAge !== "0" &&
              row.patientAge !== 0 &&
              row.patientAge !== null &&
              row.patientAge !== undefined &&
              row.patientAge !== ""
            ) {
              return h("div", row.patientAge);
            } else {
              return h("div", "--");
            }
          },
        },
        {
          title: "创建日期",
          key: "createTime",
          align: "center",
          minWidth: 100,
        },
      ],
    };
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    rowClassName(row, index) {
      if (row.patientDetailsId === this.selectedPatient.patientDetailsId) {
        return "demo-table-error-row";
      }
      return "";
    },
    showModal() {
      this.isListShow = true;
    },
    clickClose() {
      this.selectedPatient = "";
      this.isListShow = false;
      this.$store.dispatch("setToken", "");
      this.$store.dispatch("setPatInfo", "");
      sessionStorage.clear();
      // this.$parent.closeModal()
    },
    // 当前选中的患者
    clickPatient(val, index) {
      console.log(val, index);
      this.selectedPatient = this.dataList[index];
      // console.log(val, index);
    },
    clickLogin: Debounce(function () {
      if (this.selectedPatient === "") {
        return this.$Message.warning("请选择患者");
      }
      // this.$parent.add();
      this.$emit("callback", this.selectedPatient);
    }, 1500),
  },
  computed: {
    titleStr() {
      return ` 当前手机号码查询到 ${this.dataList.length} 位患者 `;
    },
  },
};
</script>

<style scoped lang="scss">
/*select-patient*/
@import "@css/modalHead";
/deep/.modalHead .ivu-modal {
  width: 1000px !important;
}
/deep/.selectpatient {
  .table-container {
    .ivu-table-body {
      cursor: pointer;
    }
  }
  .ivu-modal-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
    height: 84px;
    padding: unset !important;
    background: #e5f2fc;
    border-radius: 20px;
    .title {
      span {
        font-size: 30px;
        font-weight: 400;
        color: #010101;
      }
      span.num {
        margin: 0 5px;
        color: rgba(24, 129, 230, 1);
      }
    }
  }
  .ivu-modal-close {
    position: absolute;
    top: 30px;
    right: 40px;
    .close {
      width: 24px;
      height: 24px;
      background: url("~@/assets/img/patientend/xclose.png");
      background-position: center;
      background-size: cover;
    }
  }
  /* 定义滚动条样式 */
  ::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    background-color: rgba(240, 240, 240, 1);
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    border-radius: 10px;
    background-color: rgba(240, 240, 240, 0.5);
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    background-color: rgba(240, 240, 240, 0.5);
  }
  .ivu-modal-body {
    padding: 60px 100px;
    height: 476px;
    .ivu-row {
      margin-bottom: 80px;
      height: 65%;
      overflow-x: none;
      overflow-y: auto;
      .ivu-col {
        .ivu-form-item {
          margin-bottom: 0;
        }
        .ivu-form-item-label {
          font-size: 20px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
        }
        .ivu-form-item-content {
          .zhi {
            font-size: 20px;
            font-weight: 400;
            margin: 0 10px;
          }
        }
      }
    }
  }
  .loginbtn-box {
    display: flex;
    justify-content: center;
    .loginBtn {
      width: 550px;
      height: 70px;
      border-radius: 5px;
      border: 0;
      font-size: 30px;
      font-weight: 500;
      background: #ededed;
      color: #fff;
      cursor: pointer;
    }
    .loginBtnSelected {
      width: 550px;
      height: 70px;
      border-radius: 5px;
      border: 0;
      font-size: 30px;
      font-weight: 500;
      background: #4174f7;
      color: #fff;
      cursor: pointer;
    }
  }
}
/deep/.demo-table-error-row {
  td {
    background: #8bc0f3 !important;
    span {
      color: #fff;
    }
  }
}
/deep/ .table-container {
  padding-left: unset !important;
  padding-right: unset !important;
}
</style>
