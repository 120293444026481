
/**
 * 函数防抖 (立即执行第一次点击)
 */
export const Debounce = (func, wait) => {
    let timer;
    return function() {
      let context = this; // 这边的 this 指向谁?
      let args = arguments; // arguments中存着e
      if (timer) clearTimeout(timer);
      let callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait)
      if (callNow) func.apply(context, args);
    }
}

/**
 * 函数防抖 (n秒后执行最后一次点击)
 */
export const Debounce2 = (func, wait) => {
    let timer;
    return function() {
      let context = this; // 注意 this 指向
      let args = arguments; // arguments中存着e
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args)
      }, wait)
    }
}
